@import '~antd/dist/antd.css';

.nar {
  /* margin-top: 1%; */
  background-color: white;
}
/* .ant-layout-header {
  position: fixed;
  z-index: 5;
} */

.card {
  opacity: 0.8;
}
.card:hover {
  opacity: 1;
  color: rgb(46, 46, 46);
}
.content {
  background-color: rgb(243, 243, 243);
}
.ant-layout-header {
  color: rgba(0, 0, 0, 0.5);
  background-color: white;
}

.ant-menu-title-content {
  color: rgba(0, 0, 0, 0.5);
}

.ant-menu-title-content:hover {
  color: black;
}

.menus {
  /* margin-left: auto; */
  font-size: 1.2vw;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: solid white;
}
.ant-menu-item-selected {
  color: rgba(0, 0, 0, 0.5);
}
.ant-menu-overflow-item
  ant-menu-item
  ant-menu-item-active
  ant-menu-item-selected {
  color: rgba(0, 0, 0, 0.5);
  background-color: white;
}

.ant-menu-overflow-item
  ant-menu-item
  ant-menu-item-active
  ant-menu-item-only-child {
  color: rgba(0, 0, 0, 0.5);
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: rgba(0, 0, 0, 0.5);
}
/* @media screen and (max-width: 768px) {
  .nar {
    font-size: 16px;
  }
  .turing-title .ant-menu-title-content {
    font-size: 20px;
  }
} */
.footer {
  background-color: rgb(10, 11, 12);
  opacity: 0.8;
  padding-top: 100vh;
}
.footer_div {
  color: white;
  text-align: center;
}
.footer_div_p_name {
  font-size: 1.8vw;
  padding: 2% 0;
  /* margin: 1.2em 0 0.1em; */
}
.footer_div_p_litter {
  font-size: 0.9vw;
}
.footer_gif {
  width: 8vw;
  /* height: 5vh; */
}
.footer_app {
  width: 8vw;
}
.ant-layout-footer {
  padding: 1vw 0 0 0;
}
.col_footer_gif {
  padding: 2% 1%;
}

.slick-prev:before,
.slick-next:before {
  font-size: 2vw;
}
